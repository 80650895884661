@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(./Fonts/powdwrk5.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "MyFont2";
  src: local("MyFont2"), url(./Fonts/KILLEDDJ.ttf) format("truetype");
}

@font-face {
  font-family: "Chinese1";
  src: local("Chinese1"), url(./Fonts/hanwangkantan.ttf) format("truetype");
}

@font-face {
  font-family: "Chinese2";
  src: local("Chinese2"),
    url(./Fonts/jinmei-poster-calligraphy.ttf) format("truetype");
}

@font-face {
  font-family: "Chinese3";
  src: local("Chinese3"), url(./Fonts/zhongshan-xingshu.ttf) format("truetype");
}

@font-face {
  font-family: "Chinglish1";
  src: local("Chinglish1"), url(./Fonts/Harukaze.ttf) format("truetype");
}

@font-face {
  font-family: "Chinglish2";
  src: local("Chinglish2"), url(./Fonts/Osake.ttf) format("truetype");
}

@font-face {
  font-family: "Chinglish3";
  src: local("Chinglish3"), url(./Fonts/JAPANBRUSH.ttf) format("truetype");
}

@font-face {
  font-family: "Base";
  src: local("Base"), url(./Fonts/alata-regular.ttf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  font-family: "Base";
  transition: background-color 0.3s ease 0.3s, color 0.3s ease 0.3s,
    border-color 0.3s ease 0.3s;
}

body {
  background-color: #121217;
  background-image: linear-gradient(#121217, #272525);
}
